
body {
  background-color: #fafafa;
  font-size: 16px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

blockquote {
  background-color: #fafafa;
  padding: 5px 15px;
  margin: 0 0 15px;
  border: 1px solid #ddd;
}

.error-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(52, 58, 67, 0.1), 0 2px 5px rgba(52, 58, 67, 0.08), 0 5px 15px rgba(52, 58, 67, 0.08);
  margin: 150px auto 0;
  padding: 20px 40px;
  border-radius: 2px;
  width: 680px;
  box-sizing: border-box;
}

.error-code {
  font-size: 120px;
  display: block;
  font-weight: 600;
  text-align: left;
  line-height: 1;
  margin: 0 -40px 30px;
  text-shadow: 1px 1px 0 rgba(255,255,255,0.5);
  border-left: 7px solid #069;
  padding: 0 30px
}

.error-message h2 {
  color: #069;
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 1.3;
  text-transform: uppercase;
}

.error-message p {
  margin: 0 0 15px;
}

.error-message ul {
  margin: 0 0 20px;
  padding: 0 0 0 30px;
}

.page-redirect {
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  padding: 20px;
  text-align: right;
  box-sizing: border-box;
}

.page-redirect a {
  background-color: #333;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
  padding: 5px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.8);
  border-radius: 2px;
  transition: color 0.35s;
}

.page-redirect a:hover,
.page-redirect a:focus,
.page-redirect a:active {
  background-color: #069;
  color: #fff;
}

.debug-trace {
  margin: 20px 30px 50px;
}
.debug-trace table {
  width: 100%;
}
.debug-trace td {
    background-color: white;
    padding: 5px;
    border: 1px solid #eee;
}

@media (max-width: 780px) {
  .error-wrapper {
    margin-left: 40px;
    margin-right: 40px;
    width: auto;
  }
}

@media (max-width: 480px) {
  .error-wrapper {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin-top: 50px;
    padding: 0;
  }
}
